<template>
  <v-container
    id="regular-tables-view"
    fluid
    tag="section"
  >
    <snack-bar ref="snackBar" />
    <item-change-review
      ref="itemChangeReview"
      :change-items="changeItemsData"
      :receipt-id="itemReceiptData.id"
    />
    <test-result-migrate-request
      ref="testResultMigrateRequest"
      :item-receipt-items="itemReceiptData.items"
      :waiting-confirm-result="itemReceiptData.waiting_confirm_migrate"
    />
    <breadcrumbs :items="breadcrumbsItems" />
    <v-row justify="center">
      <v-col
        cols="12"
        md="8"
      >
        <material-card
          icon="mdi-clipboard-text"
          icon-small
          :title="itemReceiptData.po_number"
          color="accent"
        >
          <v-data-table
            dense
            :headers="itemReceiptHeader"
            :items="itemReceiptData.items"
            :item-class="markSpecialItem"
            sort-by="calories"
            class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar
                flat
              >
                <v-toolbar-title>{{ categoryName }}</v-toolbar-title>
                <v-divider
                  class="mx-4"
                  inset
                  vertical
                />
                <v-toolbar-title>Status: {{ statusName }}</v-toolbar-title>
                <v-spacer />
                <!--
                <v-dialog
                  v-model="dialog"
                  max-width="500px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-if="itemReceiptData.is_sos_order === 0"
                      color="primary"
                      dark
                      class="mb-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      New Item
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="headline">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col
                            cols="12"
                            sm="6"
                            md="4"
                          >
                            <v-text-field
                              v-model="editedItem.name"
                              disabled
                              label="Dessert name"
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="4"
                          >
                            <v-text-field
                              v-model="editedItem.calories"
                              label="Calories"
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="4"
                          >
                            <v-text-field
                              v-model="editedItem.fat"
                              label="Fat (g)"
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="4"
                          >
                            <v-text-field
                              v-model="editedItem.carbs"
                              label="Carbs (g)"
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="4"
                          >
                            <v-text-field
                              v-model="editedItem.protein"
                              label="Protein (g)"
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer />
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="close"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="save"
                      >
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog
                  v-model="dialogDelete"
                  max-width="500px"
                >
                  <v-card>
                    <v-card-title class="headline">
                      Are you sure you want to delete this item?
                    </v-card-title>
                    <v-card-actions>
                      <v-spacer />
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="closeDelete"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="deleteItemConfirm"
                      >
                        OK
                      </v-btn>
                      <v-spacer />
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                -->
              </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
              <!--
              <v-icon
                small
                class="mr-2"
                @click="editItem(item)"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                small
                @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
              -->
              <v-icon
                small
                class="mr-2"
                @click="reportItemTest(item)"
              >
                mdi-file-document-multiple
              </v-icon>
            </template>
          </v-data-table>
          <v-card-text>
            <h5>Activity</h5>
            Show:
            <v-chip
              class="ma-2"
              small
              @click="commentType='receiptComment'"
            >
              Receipt Comments
            </v-chip>
            <v-chip
              class="ma-2"
              small
              @click="commentType='receiptItemComment'"
            >
              Receipt Items Comments
            </v-chip>
            <v-card
              v-for="comment in displayComment"
              :key="comment.id"
              class="mt-2"
            >
              <v-card-subtitle
                v-if="commentType === 'receiptItemComment'"
              >
                User Name: {{ comment.user.name }} | Part Number  {{ comment.item }}
              </v-card-subtitle>
              <v-card-subtitle v-if="commentType === 'receiptComment' ">
                User Name: {{ comment.user.name }}
              </v-card-subtitle>
              <v-card-text>
                {{ comment.content }}
              </v-card-text>
              <v-card-actions>
                Update at: {{ comment.created_at }} | Type:  {{ comment.reply_type }}
                <v-spacer />
                <v-btn
                  v-if="commentType === 'comment'&& 1 === 1"
                  text
                  small
                  @click="deleteReceiptComment(comment.id)"
                >
                  Delete
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-card-text>
        </material-card>
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <material-card>
          <v-card-text>
            Vendor: {{ itemReceiptData.vendor }}
            <br>
            Is SOS Receipt: {{ itemReceiptData.is_sos_order === 1 ? 'Yes' : 'No' }}
            <br>
            Has Reviewed: {{ itemReceiptData.has_reviewed === 1 ? 'Yes' : 'No' }}
            <br>
            Request Due Data: {{ itemReceiptData.request_due_date }}
            <br>
            Estimate Due Data: {{ itemReceiptData.estimate_due_date }}
            <v-select
              v-model="receiptTaskStatus"
              class="mt-2"
              dense
              outlined
              label="Change Task Status To"
              item-text="name"
              item-value="id"
              :items="taskStatusNoAll"
              @change="updateReceiptTaskStatus()"
            />
            <follow-button :info="followInfo" />
            <v-btn
              class="mr-0 mt-2"
              color="primary"
              min-width="100"
              block
              :disabled="syncFromSosStatus"
              @click="syncFromSos"
            >
              Sync With SOS
            </v-btn>
            <v-row align="center">
              <v-col
                cols="12"
              >
                <v-textarea
                  v-model="comment"
                  label="Comment:"
                  auto-grow
                  hint="This comment is po comment"
                  clearable
                  clear-icon="mdi-close-circle"
                />
              </v-col>
            </v-row>
            <v-btn
              class="mr-0 mt-2"
              color="green"
              min-width="100"
              block
              @click="commentReceipt"
            >
              Post Comment
            </v-btn>
          </v-card-text>
          <!--
          <v-card-text>
            Order Clean
            <v-rating
              v-model="rating"
              background-color="orange lighten-3"
              color="orange"
              small
            />
            Description Accuracy
            <v-rating
              v-model="rating"
              background-color="orange lighten-3"
              color="orange"
              small
            />
            Vendor Service
            <v-rating
              v-model="rating"
              background-color="orange lighten-3"
              color="orange"
              small
            />
            Shipping
            <v-rating
              v-model="rating"
              background-color="orange lighten-3"
              color="orange"
              small
            />
            <v-btn
              class="mr-0 mt-2"
              color="primary"
              min-width="100"
              block
            >
              Submit
            </v-btn>
          </v-card-text>
          -->
        </material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import MaterialCard from '@/components/MaterialCard'
  import Breadcrumbs from '@/layouts/default/widgets/Breadcrumbs'
  import FollowButton from '@/layouts/default/widgets/FollowButton'
  import itemReceipt from '@/api/item-receipt'
  import receiptCommentApi from '@/api/receipt-comment'
  import { singleItemReceipt } from '@/util/item-receipt-data-handle'
  import ItemChangeReview from '@/components/receipt/ItemChangeReview'
  import TestResultMigrateRequest from '@/components/receipt/testResultMigrateRequest'
  import SnackBar from '@/components/app/SnackBar'
  import { get } from 'vuex-pathify'
  import store from '@/store'

  export default {
    name: 'ReceiptShow',
    components: { SnackBar, MaterialCard, Breadcrumbs, FollowButton, ItemChangeReview, TestResultMigrateRequest },
    data () {
      return {
        itemReceiptId: 0,
        itemReceiptData: [],
        categoryName: '',
        statusName: '',
        breadcrumbsItems: [],
        followInfo: {},
        comment: '',
        syncFromSosStatus: false,
        dialog: false,
        dialogDelete: false,
        rating: 4,
        itemReceiptHeader: [
          {
            text: 'Item',
            align: 'start',
            sortable: false,
            value: 'item_part_number',
          },
          { text: 'Bin #', value: 'item.bin_number', sortable: false },
          { text: 'Quantity', value: 'label_show_quantity' },
          { text: 'Lines', value: 'sos_line_info' },
          { text: 'Tested', value: 'tested_quantity' },
          { text: 'Failed', value: 'failed_in_testing' },
          { text: 'DOA', value: 'failed_in_receiving' },
          { text: 'Status', value: 'task_status.name' },
          { text: 'Update', value: 'updated_at' },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
        desserts: [],
        editedIndex: -1,
        editedItem: {
          item_part_number: '',
          failed_in_receiving: 0,
          fat: 0,
          carbs: 0,
          protein: 0,
        },
        defaultItem: {
          name: '',
          calories: 0,
          fat: 0,
          carbs: 0,
          protein: 0,
        },
        commentType: '',
        displayComment: {},
        changeItemsData: {},
        receiptTaskStatus: '',
      }
    },
    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
      },
      ...get('task', ['taskStatusNoAll']),
    },
    watch: {
      itemReceiptId (val) {
        if (val !== 0) {
          this.itemReceiptInfo(val)
          this.followInfo.id = val
          this.followInfo.dataType = 'itemReceipt'
        }
      },
      /*
      'itemReceiptData.waiting_confirm_migrate': {
        handler: function (newVal) {
          if (newVal.length > 0) this.$refs.testResultMigrateRequest.testResultMigrateRequestDialog = true
        },
        deep: true,
      },
       */
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
      commentType (val) {
        this.displayCommentData()
      },
    },
    created () {
      this.itemReceiptId = this.$route.params.id
    },
    methods: {
      itemReceiptInfo (id) {
        itemReceipt.show(id).then(response => {
          this.itemReceiptData = singleItemReceipt(response.data.data)
          console.log(this.itemReceiptData)
          this.categoryName = this.itemReceiptData.category.name
          this.statusName = this.itemReceiptData.task_status.name
          this.generateBreadCrumbs()
        }).catch(error => {
          console.log(error)
        })
      },
      markSpecialItem (item) {
        if (item.removed_at) return 'table-remove-item table-done-item'
        if (item.task_status_id === 4) return 'table-issue-item'
        if (item.task_status_id >= 3) return 'table-done-item'
      },
      generateBreadCrumbs () {
        this.breadcrumbsItems = []
        const table = {
          text: 'Item Receipt Table',
          disabled: false,
          to: { name: 'Item Receipt' },
        }
        const receipt = {
          text: this.itemReceiptData.po_number,
          disabled: true,
          to: { name: 'Receipt Show', params: { id: this.itemReceiptData.id } },
        }
        this.breadcrumbsItems.push(table)
        this.breadcrumbsItems.push(receipt)
      },
      displayCommentData () {
        if (this.commentType === 'receiptComment') {
          this.displayComment = Object.assign({}, this.itemReceiptData.comment)
        }
        if (this.commentType === 'receiptItemComment') {
          this.displayComment = Object.assign({}, this.itemReceiptData.item_receipt_item_comment)
        }
      },
      reportItemTest (item) {
        this.$router.push({ name: 'Receipt Item', params: { id: item.id } }).catch(() => {})
      },
      commentReceipt () {
        receiptCommentApi.store(this.itemReceiptId, this.comment).then(() => {
          this.itemReceiptInfo(this.itemReceiptId)
        }).catch(error => {
          console.log(error)
        })
      },
      deleteReceiptComment (id) {
        receiptCommentApi.delete(id).then(() => {
          this.itemReceiptInfo(this.itemReceiptId)
        }).catch(error => {
          console.log(error)
        })
      },
      updateReceiptTaskStatus () {
        itemReceipt.changeTaskStatus({
          id: this.itemReceiptId,
          task_status_id: this.receiptTaskStatus,
        }).finally(() => {
          this.receiptTaskStatus = ''
          this.itemReceiptInfo(this.itemReceiptId)
        })
      },
      syncFromSos () {
        this.syncFromSosStatus = true
        itemReceipt.syncItemReceipt({
          sos_receipt_number: this.itemReceiptData.po_number,
          type: 'receipt_number',
          sos_receipt_id: null,
        }).then(response => {
          if (response.data.code === 20001) {
            console.log(response.data.data.remove_items)
            this.changeItemsData = response.data.data
            this.$refs.itemChangeReview.ItemChangeReviewDialog = true
          }
          this.syncFromSosStatus = false
        }).catch(error => {
          this.syncFromSosStatus = false
          store.set('message/storeMessage', {
            color: 'error',
            text: error,
            timeout: 3000,
          })
          this.$refs.snackBar.activeStatusInfo()
        })
      },
    },
  }
</script>

<style scoped>

</style>
